
<template>
  <div>
    <section class="section is-main-section">
      <tiles>
        <card-component
          title="Privacy Policy"
          icon="bank"
          class="tile is-child"
        >
          <div class="content">
            <p>This Privacy Policy statement explains which personal data are collected when creating a project on ASTERICS, the purpose of data collection, and how these data are collected, processed and secured. By General Data Protection Regulation (EU) 2016/679 (GDPR), you are granted the rights to access, rectify, and erase your personal data. Contact details to express your rights are provided below.</p>
            <p>There is no privacy information related to cookies (no cookies).</p>
            <h2>General information</h2>
            <p>Refer to the <router-link class="link" to="/legalnotice">legal notice</router-link> and to the <router-link class="link" to="/about">terms of service</router-link></p>
            <h2>Personal data: ASTERICS website</h2>
            <p>This website collects personal data in the following instances:</p>
            <ul>
              <li>Login: IP address, brower, operating system, date, time, pages viewed and technical information relating to response times.</li>
              <li>Project account: email, data and time of first and last time the project was visited.</li>
            </ul>
            <p>These personal data are processed electronically by the relevant controller and the website publisher, such as specified in the <router-link class="link" to="/legalnotice">legal notice</router-link> of the said website.</p>
            <p>Such personal data are required for the website to perform correctly with:</p>
            <ul>
              <li>Information collected by server logs</li>
              <li>All the data required to create the project account</li>
            </ul>
            <p>This website uses strictly necessary cookies to function (authentication cookies), that are processed similarly as the other collected personal data and deleted using the same policy.</p>
            <p>The purpose of the personal data is to implement the website, measure traffic and address response time incidents. Log data are used to monitor for suspicious activities (e.g. attempts to hack the site), to diagnose problems on the site, and to create anonymous usage statistics. We do not attempt to identify or profile people based on these data. Emails are collected to allow contacting users in case of incident, data loss, maintenance issues, or any important information related that could impact the user’s use of ASTERICS. The legal basis for processing your personal data is to provide a service of general interest.</p>
            <p>Data storage periods: Users' emails are kept during the duration of the associated project (one month after the last visit to the project page). Web server logs are kept for one year. At the end of the stated storage periods, data will be destroyed.</p>
            <h2>Personal data: Email to support</h2>
            <p>We use the Genotoul Bioinfo ticket system to manage support on ASTERICS. Information related to privacy policy for support is described in Section "Support demand forms" on <a target="_blank" href="http://bioinfo.genotoul.fr/index.php/about-us/legal/privacy-policy/">this webpage</a>.</p>
            <h2>Recipients, access and data protection officer</h2>
            <p>The recipients of the personal data are authorised personnel working in the controller's services, partners/supervisory bodies and providers in charge of managing and monitoring users, and implementing the website and the services offered.</p>
            <p>You have the right of access, the right to rectification, the right to object on legitimate grounds, the right to restriction of processing and the right to erasure for all your personal data in accordance with the terms and conditions set forth in France's Data Protection Act 1978, as amended, reinforced and supplemented by the GDPR (General Data Protection Regulation) which became effective on 25 May 2018. You may exercise these rights by contacting the publishing manager, such as specified in the legal notice, or by writing to the address indicated in the said notice. If there are doubts about the identity of the person wishing to exercise such rights, a signed identity document will be required.</p>
            <p>The controller has designated a Data Protection Officer (DPO). You can contact the DPO by email at cil-dpo@inrae.fr or by writing to INRAE, 24, chemin de Borde Rouge - Auzeville, CS52627, 31326 Castanet Tolosan cedex, France. If you believe that there is a breach of your data protection rights even after contacting the controller, you can lodge a complaint with the data protection authority (CNIL) by writing to the following address: Commission Nationale de l'Informatique et des Libertés, 3 Place de Fontenoy, TSA 80715, 75334 PARIS CEDEX 07 or online at <a target="_blank" href="http://www.cnil.fr/">http://www.cnil.fr/</a>.</p>
          </div>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  components: {
    'tiles': () => import('@/components/ui/Tiles.vue'),
    'card-component': () => import('@/components/ui/CardComponent.vue')
  }
}
</script>
